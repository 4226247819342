import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import team01 from "../assets/team01.webp"
import Testimonial from '../Home/Testimonial';

import { useDispatch, useSelector } from 'react-redux';
import { fetchTeamLinks } from '../../features/teamsData/teamsSlice'; // Named import
import { StorageLink } from '../../app/Apilink';


const OurTeam = () => {

    const dispatch = useDispatch();
    const { data: teamlink, status, error } = useSelector((state) => state.teamlink);
  
    useEffect(() => {
      if (status === 'idle') {
        dispatch(fetchTeamLinks());
      }
   
    }, [status, dispatch]);
  
    

  const experts = [
    {
        name: "Jill Cortez",
        category: "Web Designer",
        image: team01
    },
    {
        name: "Calvin Roy",
        category: "Web Developer",
        image: team01
    },
    {
        name: "Christie Pratt",
        category: "Training Expert",
        image: team01
    },
    {
        name: "Ben Jonson",
        category: "Seo Expert",
        image: team01
    }
];
useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
       <section className="rows bg-fixed team-banner" >
       <div className="container">
    <div className="row">
      <div className="col-xs-12 col-md-7">
        <h1 className="text-white font-weight-bold home_h2 wow  fadeInDown   animated"> Our Strength</h1>

        <p className="mt-2">Meet our team </p>

      </div>

    </div>
  </div></section> 
  <section className="breadcrumbs-main">  <div className="container">



<ul id="breadcrumb" className="breadcrumb"><li className="item-home"><Link className="bread-link bread-home" to="/" title="Home">Home</Link></li><li className="item-current item-31259"> Our Leadership</li></ul>


</div></section>

<div className="expert-wrap">
            <div className="container">
                <div className="title title_center">
                    <h1>Our Experts</h1>
                </div>
                <div className="row">
                    {teamlink?.map((expert, index) => (
                        <div className="col-lg-3 col-md-6" key={index}>
                            <div className="experts">
                                <div className="teamImg">
                                    <img src={`${StorageLink}/${expert.img}`} alt="" />
                                    <ul className="social-icons list-inline">
                                        <li className="social-facebook">
                                            <Link to="#"><FaFacebookF aria-hidden="true" /></Link>
                                        </li>
                                        <li className="social-twitter">
                                            <Link to="#"><FaTwitter aria-hidden="true" /></Link>
                                        </li>
                                        <li className="social-linkedin">
                                            <Link to="#"><FaLinkedinIn aria-hidden="true" /></Link>
                                        </li>
                                        <li className="social-googleplus">
                                            <Link to="#"><FaInstagram aria-hidden="true" /></Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="team_content">
                                    <h5><Link to="#">{expert.name}</Link></h5>
                                    <p className="category">{expert.post}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

        {/* <Testimonial /> */}
    </>
  )
}

export default OurTeam
