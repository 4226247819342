import React from 'react'

import TopBar from './Components/Navbar/TopBar'
import { Route, Routes } from "react-router-dom";
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Navbar/Footer';
import "./responsive.css"
import CEHv12 from './Components/Courses/CEHv12';
import TrainingCalender from './Components/TraiiningCalender/TrainingCalender';
import Privacy from './Components/Navbar/Privacy';
import OurTeam from './Components/Navbar/OurTeam';
import FormMain from './Components/Navbar/FormMain';
import FreeEvents from './Components/TraiiningCalender/FreeEvents';
import FreeTest from './Components/TraiiningCalender/FreeTest';
import FreeCyber from './Components/TraiiningCalender/FreeCyber';
import Blog from './Components/TraiiningCalender/Blog';
import AboutUs from './Components/TraiiningCalender/AboutUs';
import PopUpForm from './Components/Navbar/PopUpForm';
import BannerCarousel from './Components/Home/Hero';
import ContactUs from './Components/Navbar/ContactUs';
import CoursePages from './Components/TraiiningCalender/CoursePages';
import Faq from './Components/Courses/Faq';
import BlogInner from './Components/TraiiningCalender/BlogInner';
import Dashboard from './Components/Dashboard/Dashboard';
import Myacc from './Components/Dashboard/Myacc';

import Cartpage from './Components/carts/Cardpage'
import Schedule from './Components/Schedule/Schedule';
import Trainee from './Components/Trainee/Trainee';
const App = () => {
  return (
    <>
  
   <Navbar />
   <Routes>
        <Route path="/" element={<BannerCarousel />} />
        <Route path="/certified-ethical-hacker-ceh-training" element={<CEHv12 />} />
        <Route path="/:slug" element={<CEHv12 />} />
        <Route path="/training-calendar" element={<TrainingCalender />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/leadership" element={<OurTeam />} />
        <Route path="/training-partner" element={<FormMain />} />
        <Route path="/free-events" element={<FreeEvents />} />
        {/* <Route path="/free-practice-tests" element={<FreeTest />} /> */}
        {/* <Route path="/free-cybersecurity-training" element={<FreeCyber />} /> */}
        <Route path="/blog" element={<Blog />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/courses" element={<CoursePages />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/blog-inner" element={<BlogInner />} />
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        <Route path="/dashboard" element={<Myacc/>} />
        <Route path="/:crname/schedule" element={<Schedule/>} />


        <Route path='/cart' element={<Cartpage/>} />
        <Route path='/business-solutions' element={<Trainee/>} />



        {/* /business-solutions */}
       

      </Routes>

     
<Footer />
    
    </>
  )
}

export default App
