import React, { useState, useEffect } from 'react';
import "../../App.css"
import { Link } from 'react-router-dom';
const TopBar = () => {

  const [days, setDays] = useState(6);
  const [hours, setHours] = useState(11);
  const [minutes, setMinutes] = useState(50);
  const [seconds, setSeconds] = useState(35);


  useEffect(() => {
    const countdown = setInterval(() => {
   
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        if (minutes > 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          if (hours > 0) {
            setHours(hours - 1);
            setMinutes(59);
            setSeconds(59);
          } else {
            if (days > 0) {
              setDays(days - 1);
              setHours(23);
              setMinutes(59);
              setSeconds(59);
            }
          }
        }
      }
    }, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(countdown);
  }, [days, hours, minutes, seconds]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="offer-header">
      {/* <img src="/assets/images/diwali-offer/left-top-icon24.png" className="left-friday" /> */}
      <div className="container-fluid px-3 py-1 px-md-4 py-md-2">
        <div className="row text-center">
        <div class="col-12 d-md-flex justify-content-center align-items-center d-sm-block ">
            <div className="text d-md-flex align-items-center">
              UP TO
              <strong style={{ color: '#FFDD00', padding: '0 8px' }}> 50% </strong>
              OFF on Combo Courses!
            </div>
            <div className="link">
              <Link to="#">CLAIM NOW</Link>
            </div>
            <div className="d-none d-md-flex mt-2 mt-lg-0">
              <div className="time">
                <strong id="countdownt">{days}</strong>
                <span>D</span>
                <strong id="countdownt1">{hours}</strong>
                <span>H</span>
                <strong id="countdownt2">{minutes}</strong>
                <span>M</span>
                <strong id="countdownt3">{seconds}</strong>
                <span>S</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <img src="/assets/images/diwali-offer/right-top-icon24.png" className="right-friday" /> */}
    </div>
  );
};

export default TopBar;
