import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import course1 from "../assets/CIPP-E-min.webp"
import course2 from "../assets/CIPM-min.webp"
import course3 from "../assets//Data-Protection-Officer-DPO.webp"
import course4 from "../assets/Certified-Information-Privacy-Technologist-CIPT.webp"
import Testimonial from "../Home/Testimonial";
import CourseDetails from "../Home/CourseDetails";

const CoursePages = () => {
    const courses = [
        { id: 33497, category: 'Data Privacy', title: 'CIPP/E European Privacy Online Training', imgSrc: course1, link: '/certified-ethical-hacker-ceh-training' },
        { id: 39518, category: 'Data Privacy', title: 'CIPM Certification Training', imgSrc: course2, link: '/certified-ethical-hacker-ceh-training' },
        { id: 41268, category: 'Data Privacy', title: 'DPO Hands-on Training – Aligned to DPDPA and GDPR', imgSrc: course3, link: '/certified-ethical-hacker-ceh-training' },
        { id: 39514, category: 'GRC Audit', title: 'CIPT Certification Training', imgSrc: course4, link: '/certified-ethical-hacker-ceh-training' },
        { id: 38513, category: 'GRC Analyst', title: 'Certified Ethical Hacker (CEH)', imgSrc: course1, link: '/certified-ethical-hacker-ceh-training' },
        { id: 40512, category: 'Infosec Management', title: 'Certified Information Systems Security Professional (CISSP)', imgSrc: course2, link: '/certified-ethical-hacker-ceh-training' },
        { id: 37511, category: 'Compliance', title: 'Certified Information Security Manager (CISM)', imgSrc: course3, link: '/certified-ethical-hacker-ceh-training' },
        { id: 41512, category: 'Cloud Security', title: 'Certified Cloud Security Professional (CCSP)', imgSrc: course4, link: '/certified-ethical-hacker-ceh-training' },
        { id: 42514, category: 'Infosec Management', title: 'Certified Information Systems Auditor (CISA)', imgSrc: course1, link: '/certified-ethical-hacker-ceh-training' },
        { id: 43515, category: 'Compliance', title: 'Certified in Risk Systems Control (CRISC)', imgSrc: course2, link: '/certified-ethical-hacker-ceh-training' },
        { id: 44516, category: 'Data Privacy', title: 'GDPR Foundation and Practitioner', imgSrc: course3, link: '/certified-ethical-hacker-ceh-training' },
        { id: 45517, category: 'Cloud Security', title: 'AWS Certified Security – Specialty', imgSrc: course4, link: '/certified-ethical-hacker-ceh-training' },
      ];
      
      const categories = [
        'All Courses',
        'Data Privacy',
        'GRC Audit',
        'GRC Analyst',
        'Infosec Management',
        'Compliance',
        'Cloud Security',
      ];
      
        const [activeCategory, setActiveCategory] = useState('All Courses');
      
        const handleCategoryClick = (category) => {
          setActiveCategory(category);
        };
      
        const filteredCourses = activeCategory === 'All Courses'
          ? courses
          : courses.filter(course => course.category === activeCategory);
      
          useEffect(() => {
            window.scrollTo(0, 0);
          }, []);
        
  
  return (
    <>
      <section className="course-category-banner">
        <div className="container">
          <div className="container py-5">
            <ul id="breadcrumb" className="breadcrumb">
              <li className="item-home">
                <Link
                  className="bread-link bread-home"
                  to="/"
                  title="Home"
                >
                  Home
                </Link>
              </li>
              <li className="item-cat item-custom-post-type-courses ">
                <Link
                  className="bread-cat bread-custom-post-type-courses"
                  to=""
                  title="All Courses"
                >
                  All Courses
                </Link>
              </li>
            
            </ul>
          </div>

          <div className="row justify-content-between align-items-center mt-2">
            <div className="col-lg-7">
              <div className="item">
                
                <div>
                  
                </div>
                <div className="highlights-row">
                  <div className="highlights">
                    
                  </div>
                  <div className="highlights">
                 
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="course-category-carrer-top" id="courses">
       <div className="container">
       <div className="row tab-responsiblities p-0 m-0">
          <div className="col-12">
            <h2 className="course-category-h2 text-center">
              Explore Our Top Training Programs
            </h2>
            <div className="nav flex-column nav-pills" style={{ width: '100%', maxWidth: '100%', flexDirection: 'row !important' }}>
        {categories.map((category, index) => (
          <Link
            key={index}
            className={`nav-link ${activeCategory === category ? 'active' : ''}`}
            onClick={() => handleCategoryClick(category)}
            role="tab"
            aria-selected={activeCategory === category}
          >
            {category}
          </Link>
        ))}
      </div>

      <div className="tab-content  p-md-4 p-sm-0">
        <div className="tab-pane fade show active">
          <div className="row justify-content-between">
            <div className="col-12">
              <div className="row">
                {filteredCourses.map(course => (
                  <div className="col-lg-3 col-md-4" key={course.id}>
                    <Link to={course.link} className="item">
                      <img src={course.imgSrc} alt={course.title} className="w-100 d-block mx-auto" loading="lazy" />
                      <div className="item-body">
                        <h4>{course.title}</h4>
                        <button>Read More</button>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
          </div>
        </div>
      </div>
    </div>
            
          </div>
        </div>
       </div>
       
      </div> */}

      <CourseDetails />
<div className="mt-90" >
{/* <Testimonial /> */}
</div>  
    </>
  );
};

export default CoursePages;
