import { useEffect , useState} from "react";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaBasketballBall, FaLocationArrow, FaPhoneVolume, FaEnvelope } from 'react-icons/fa';
import { Link } from "react-router-dom";
import imag from "../assets/Blue.webp"
const ContactUs = () => {

  const [formData, setFormData] = useState({
    contactName: "",
    contactEmail: "",
    subject: "",
    contactMessage: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    
  };


  return (
    <>
      <section className="rows bg-fixed privacy-banner">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-7">
              <h1 className="text-white font-weight-bold home_h2 wow  fadeInDown   animated">
          
                Contact Us
              </h1>

              <p className="mt-2 text-light">Submit Your Query </p>
            </div>
          </div>
        </div>
      </section>

      <section className="home-form px-md-3 px-sm-0 py-5">
        <div className="container">
          <div className="row">
          <div className="col-lg-6">
       
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d109782.87829261528!2d76.61497891320774!3d30.698295388807644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fee906da6f81f%3A0x512998f16ce508d8!2sSahibzada%20Ajit%20Singh%20Nagar%2C%20Punjab!5e0!3m2!1sen!2sin!4v1720091395240!5m2!1sen!2sin"
      className="map-container"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
            </div>
            <div className="home-main-fom col-lg-6">
            
    <div
      className="contact-main__form fade-top"
      style={{ transform: "translate(0px, 0px)", opacity: 1 }}
    >
      <h3>Leave a Message</h3>
      <form onSubmit={handleSubmit} className="section__content-cta">
        <div className="group-wrapper mb-3">
          <div className="group-input mb-3">
            <input
              type="text"
              name="contactName"
              id="contactName"
              placeholder="Name"
              value={formData.contactName}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="group-input mb-3">
            <input
              type="email"
              name="contactEmail"
              id="contactEmail"
              placeholder="Email"
              value={formData.contactEmail}
              onChange={handleChange}
              className="form-control"
            />
          </div>
        </div>
        <div className="group-input mb-6">
          <select
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            className="form-select"
          >
            <option value="" disabled>
              Subject
            </option>
            <option value="Account">Account</option>
            <option value="Service">Service</option>
            <option value="Pricing">Pricing</option>
            <option value="Support">Support</option>
          </select>
        </div>
        <div className="group-input mb-3">
          <textarea
            name="contactMessage"
            id="contactMessage"
            placeholder="Message"
            value={formData.contactMessage}
            onChange={handleChange}
            className="form-control "
            style={{height:'100px'}}
          ></textarea>
        </div>
        <div className="form-cta justify-content-start">
          <button type="submit" className="btn btn-submit-contact">
            Send Message
          </button>
        </div>
      </form>
    </div>
    </div>

          
          </div>
        </div>
      </section>

   
      <div className="container">
      <div className="row gaper justify-content-around">
        <div className="col-12 col-lg-5 col-xl-4">
          <div className="footer-two__left">
              <div className="d-flex gap-3 section__content-cta">
            <div className="logo">
              <Link to="/">
                <img 
                  alt="Image"
                  fetchpriority="high"
                  width="160"
                  height="40"
                  decoding="async"
                  style={{color: 'transparent'}}
                 src={imag}
                />
              </Link>
            </div>
              <h2>
                <Link className="folks-text animated-text" to="mailto:info@gmail.com">
                  info@gmail.com
                </Link>
              </h2>
            </div>
            <div className="paragraph">
              <p>Welcome to our digital agency. We specialize in helping businesses like yours succeed online. From website design and development.</p>
            </div>

          </div>
        </div>
        <div className="col-12 col-lg-7 col-xl-7 ">
          <div className="footer-two__right">
            <div className="social_contact">
              <Link target="_blank" to="https://www.facebook.com/">
                <FaFacebookF />
                <span>Facebook</span>
              </Link>
              <Link target="_blank" to="https://www.twitter.com/">
                <FaTwitter />
                <span>Twitter</span>
              </Link>
              <Link target="_blank" to="https://www.pinterest.com/">
                <FaLinkedinIn />
                <span>Linkedin</span>
              </Link>
              <Link target="_blank" to="https://www.instagram.com/">
                <FaInstagram />
                <span>Instagram</span>
              </Link>
            
            </div>
            <div className="footer__single-meta section__content-cta">
              <Link target="_blank" to="https://www.google.com/maps/d/viewer?mid=1UZ57Drfs3SGrTgh6mrYjQktu6uY&amp;hl=en_US&amp;ll=18.672105000000013%2C105.68673800000003&amp;z=17">
                <FaLocationArrow />
               Mohali
              </Link>
              <Link to="tel:406-555-0120">
                <FaPhoneVolume />
                (406) 555-0120
              </Link>
              <Link to="mailto:info@xpovio.com">
                <FaEnvelope />
                info@xpovio.com
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    </>
  );
};

export default ContactUs;
