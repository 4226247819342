import React, { useState } from 'react'
import { faqs } from '../CommonData/CardsDetailData'
import { FaAngleRight } from 'react-icons/fa'

const Faq = ({data}) => {
    const [openIndex, setOpenIndex] = useState(0);

    const handleToggle = (index) => {
      setOpenIndex(openIndex === index ? null : index);
    };


  return (
    <>
    <div className="container mb-4">
    <h3 className="head11 " id="faqs">
      Certified DevSecOps Engineer <strong class="text-primary">FAQs</strong>
    </h3>
    <div className="card-faq faqs_cor  rows">
      <div id="demo_text">
        <div className="wp_acc_wrap">
          {data.map((faq, index) => (
            <div className="ap_wrap" key={index}>
              <h5 className="ap_title" onClick={() => handleToggle(index)} >
                {faq.question} <FaAngleRight className={`icon-faq ${openIndex === index ? 'rotated-icon' : ''}`} />
              </h5>
              <div
                className={`ap_content ${openIndex === index ? 'open' : ''}`}
                dangerouslySetInnerHTML={{ __html: faq.answer }}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>

    
    </>
  )
}

export default Faq
